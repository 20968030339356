import Vue from 'vue'
import VueRouter from 'vue-router'
import routers from './router/router'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import animated from 'animate.css'
Vue.use(animated)
Vue.use(VueAxios, axios)
Vue.use(VueRouter)
Vue.config.productionTip = false
import { getUrlCode } from './utils/compress.js'
import globalWebSocket from './utils/global.js'
Vue.prototype.$globalWebSocket = globalWebSocket
/*引入 element-ui -start*/
import 'element-ui/lib/theme-chalk/index.css';
import {
  Message,
  Loading,
  Dialog,
  Input,
  Button,
  Image,
  Badge,
  Upload,
  Progress,
  Radio,
  Table,
  TableColumn,
  Pagination,
  Card,
  MessageBox,
  Drawer,
  Steps,
  Step
} from 'element-ui';
Vue.use(Loading);
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.use(Dialog);
Vue.use(Input);
Vue.use(Button);
Vue.use(Image);
Vue.use(Badge);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Radio);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Card);
Vue.use(Drawer);
Vue.use(Steps);
Vue.use(Step);
/*引入 element-ui -end*/
/*增加版本控制*/
const VUE_APP_VERSION = require('../package.json').version
const VersionNum = window.localStorage.getItem("Version");
if(VUE_APP_VERSION !== VersionNum){
  localStorage.clear()
  window.localStorage.setItem("Version", VUE_APP_VERSION);
  location.reload()
}
const router = new VueRouter({
  mode: 'history',
  base: '/',//部署的子路径
  routes: routers
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // 判断是不是微信环境
  if (to.name !== 'webLogin' && !sessionStorage.getItem("token") && to.name !== 'login'){
    let query = getUrlCode();
    // 记录打开的是那个页面，登录成功后再跳回去
    sessionStorage.setItem("redirect", location.pathname.substr(1))
    sessionStorage.setItem("search", JSON.stringify(query))
    next({ name: 'login' })
  } else {
    next()
  }
})
new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
