/*路由设置文件*/
const routers = [
    {
        path: '/',
        name: 'home',
        meta:{index:1,title: '秀码编程-首页'},
        component: () => import('../views/index/index.vue')
    },
    {
        path: '/home',
        name: 'home',
        meta:{index:1,title: '秀码编程-首页'},
        component: () => import('../views/index/index.vue')
    },
    {
        path: '/live',
        name: 'live',
        meta:{index:1,title: '直播'},
        component: () => import('../views/live/index.vue')
    },
    {
        path: '/webLogin',
        name: 'webLogin',
        meta:{index:1,title: '登录'},
        component: () => import('../views/index/webLogin.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta:{index:1,title: '登录'},
        component: () => import('../views/index/login.vue')
    },
    {
        path: '/teaching',
        name: 'teaching',
        meta:{index:1,title: '上课'},
        component: () => import('../views/teaching/index.vue')
    },
    {
        path: '/answer',
        name: 'answer',
        meta:{index:1,title: '答题'},
        component: () => import('../views/teaching/answer.vue')
    },
    {
        path: '/course',
        name: 'course',
        meta:{index:1,title: '我的课程'},
        component: () => import('../views/course/index.vue')
    },
    {
        path: '/works',
        name: 'works',
        meta:{index:1,title: '我的作品'},
        component: () => import('../views/works/index.vue')
    },
    {
    path: '/',
        component: () => import('../views/index/login.vue')
    },
]
export default routers
