<script src="../../chat-help/src/main.js"></script>
<template>
  <div id="app">
    <div
            class="app-hint"
            v-if="isScreen === true"
    >
      <div class="rotate">
        <div class='center'>
          <div class='smartphone'>
            <div class='content'>
              <ul>
                <li></li>
                <li></li>
                <li></li>
                <li style='width: 90%'></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li style='width: 60%'></li>
                <li></li>
                <li></li>
                <li style='width: 70%'></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="notice-text">请使用电脑打开！</div>
      <div class="mask"></div>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      screenHW: window.orientation, // 判断横竖屏
      isScreen: true, // 横竖屏
    };
  },
  mounted() {
    this.screenHW = window.orientation  //保证刷新时获取状态准确

    // 监听窗口大小
    window.onresize = () => {
      return (() => {
        this.screenHW = window.orientation   // 把横竖屏的变化赋值
      })()
    }
  },
  watch: {
    screenHW: {
      immediate: true,
      handler(newval, old) {
        this.rotate()
      }

    }
  },
  created() {
    this.initWebSocket();
  },
  methods: {
    // 判断横竖屏
    rotate() {
      // 判断是手机  还是 电脑端
      if(this._isMobile()){
        if (Math.abs(this.screenHW) === 90) {
          // 横屏
          this.isScreen = false
        } else {
          // 竖屏
          this.isScreen = true
        }
      }else{
        this.isScreen = false
      }
    },
    // 判断是手机端 还是电脑端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    // 初始化 websocket
    initWebSocket() {
      let that = this;
      if ("WebSocket" in window) {
        // 创建websocket
        if(document.domain==='localhost'){
          // that.ws = new WebSocket("ws://"+document.domain+":8282");
          that.ws = new WebSocket("wss://api.nanshikeji.cn/wss");
        }else{
          that.ws = new WebSocket("wss://api.nanshikeji.cn/wss");
        }
        that.$globalWebSocket.setWs(that.ws);
        // that.ws.onopen = that.onopen();
        that.ws.onopen = function() {
          console.log('webSocket connect successful')
        };
        that.ws.onclose = function() {
          // 关闭 websocket
          console.log("webSocket connect closed");
          setTimeout(() => {
            that.initWebSocket();
          }, 2000);
        };
      } else {
        // 浏览器不支持 WebSocket
        console.log("您的浏览器不支持 WebSocket!");
      }
    },
  }
}
</script>

<style>
  #app {
    background-color: #F8F8F8;
  }
  html{font-size:16px;}
  a,address,b,big,blockquote,body,center,cite,code,dd,del,div,dl,dt,em,fieldset,font,html,i,iframe,
  img,ins,label,legend,li,ol,p,pre,small,span,strong,u,ul,var {
    margin: 0;
    padding: 0;
    outline: none;
  }
  ul,li,ol{list-style:none;outline: none;}
  img,fieldset{border:0; }
  img{display:block; outline: none;}
  a{text-decoration:none; outline: none;}
  h1,h2,h3,h4,h5,h6{font-weight:100;}
  body{outline: none;}
  input,a{background:none; outline:none; border:none;}
  em,i,u {
    font-style: normal
  }

  .app-hint{
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .notice-text{
    color: #ffffff;
    font-size: 1.5rem;
    margin-top: 2rem;
  }
  .center {
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 90px;
    height: 155px;
    /* Smartphone */
  }
  .smartphone {
    width: 80px;
    height: 120px;
    margin: 40px 0 0 0;
    border: 5px #e1e1e1 solid;
    border-top-width: 15px;
    border-bottom-width: 20px;
    border-radius: 4px;
    -webkit-animation: smartphone 2s ease-in-out infinite;
    animation: smartphone 2s ease-in-out infinite;
  }
  @-webkit-keyframes smartphone {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 0;
    }
    25% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 1;
    }
    50% {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
    75% {
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0;
    }
  }
  @keyframes smartphone {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 0;
    }
    25% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 1;
    }
    50% {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
    75% {
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0;
    }
  }
  .smartphone:before {
    content: '';
    display: block;
    width: 10px;
    height: 3px;
    margin: -9px auto;
    background: #c8c8c8;
    border-radius: 4px;
  }
  .smartphone:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    margin: 4px auto;
    background: #c8c8c8;
    border-radius: 10px;
  }
  .smartphone .content {
    width: 60px;
    height: 100px;
    margin: 15px 0 0 0;
    padding: 10px;
    background: #9b3c6f;
    color: #762e55;
    font-size: 0.5em;
    overflow: hidden;
  }
  .smartphone ul li {
    height: 6px;
    margin: 0 0 5px 0;
    border-color: #762e55;
    background: #762e55;
  }
  .smartphone ul {
    -webkit-animation: screen 2s ease-in-out infinite;
    animation: screen 2s ease-in-out infinite;
  }
  @-webkit-keyframes screen {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 0;
    }
    25% {
      opacity: 1;
      -webkit-transform-origin: left;
      transform-origin: left;
      -webkit-transform: rotate(0deg) translateY(0%) translateX(0%) scaleX(1);
      transform: rotate(0deg) translateY(0%) translateX(0%) scaleX(1);
    }
    50% {
      -webkit-transform-origin: left;
      transform-origin: left;
      -webkit-transform: rotate(90deg) translateY(-1%) translateX(-217%) scaleX(1.7);
      transform: rotate(90deg) translateY(-1%) translateX(-217%) scaleX(1.7);
    }
    75% {
      opacity: 1;
    }
    100% {
      -webkit-transform-origin: left;
      transform-origin: left;
      -webkit-transform: rotate(90deg) translateY(-1%) translateX(-217%) scaleX(1.7);
      transform: rotate(90deg) translateY(-1%) translateX(-217%) scaleX(1.7);
      opacity: 0;
    }
  }
  @keyframes screen {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 0;
    }
    25% {
      opacity: 1;
      -webkit-transform-origin: left;
      transform-origin: left;
      -webkit-transform: rotate(0deg) translateY(0%) translateX(0%) scaleX(1);
      transform: rotate(0deg) translateY(0%) translateX(0%) scaleX(1);
    }
    50% {
      -webkit-transform-origin: left;
      transform-origin: left;
      -webkit-transform: rotate(90deg) translateY(-1%) translateX(-217%) scaleX(1.7);
      transform: rotate(90deg) translateY(-1%) translateX(-217%) scaleX(1.7);
    }
    75% {
      opacity: 1;
    }
    100% {
      -webkit-transform-origin: left;
      transform-origin: left;
      -webkit-transform: rotate(90deg) translateY(-1%) translateX(-217%) scaleX(1.7);
      transform: rotate(90deg) translateY(-1%) translateX(-217%) scaleX(1.7);
      opacity: 0;
    }
  }
</style>
